import axios from 'axios';
import store from '../../../store/index'
import {backendApi} from "../../backend-api-sr"

export const projectTracking = (() =>{
    const fetchApplConstant = ( async (subPath) => {
        var url = '/api/v3/sr/project/project-tracking/filter-appl-constant'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchCity = ( async (subPath) => {
        var url = '/api/v3/sr/project/project-tracking/filter-city'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchPartner = ( async (subPath) => {
        var url = '/api/v3/sr/project/project-tracking/filter-partner'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchProvince = ( async (subPath) => {
        var url = '/api/v3/sr/project/project-tracking/filter-province'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchRegion = ( async (subPath) => {
        var url = '/api/v3/sr/project/project-tracking/filter-region'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchRollFormer = ( async (subPath) => {
        var url = '/api/v3/sr/project/project-tracking/filter-roll-former'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchSalesman = ( async (subPath) => {
        var url = '/api/v3/sr/project/project-tracking/filter-salesman'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchHeader = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/fetch-header'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchDetail = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/fetch-detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchImages = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/fetch-images'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const insertData = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/create-project'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const updateData = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/update-project'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const deleteData = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/delete-project'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const closedData = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/close-project'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cekStatus = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/cek-status'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const insertDetail = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/create-project-detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        // return await backendApi.fetchCore(url, reqBody, false, false, false)
        return await axios.post(`${process.env.VUE_APP_URL}${url}`, reqBody ,
        { 
            headers: { 
                'Authorization': `Bearer ${store.getters.isTokenUser}`, 
                'Content-Type': 'multipart/form-data' 
            } 
        })
    })

    const deleteDetail = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/delete-project-detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getDetailPartner = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/project/project-tracking/detail-partner'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchApplConstant, fetchCity, fetchPartner, fetchProvince, fetchRegion, fetchRollFormer, fetchSalesman, fetchHeader, cekStatus, fetchDetail, fetchImages, insertData, updateData, deleteData, closedData, insertDetail, deleteDetail, getDetailPartner};
})()