<template>
  <v-container fluid class="p-4" style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                        >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-col>
      </v-row>
      <v-row class="mt-3">
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="datas"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    height="500"
                                    fixed-header
                                    class="elevation-1"
                                    :search="search"
                                    >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">Result</p>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" @click="create()"><v-icon>mdi-plus</v-icon> New Partner</v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.active_flag`]="{ item }">
                                        <v-switch
                                            v-model="item.active_flag"
                                            inset
                                            color="primary"
                                            class="pt-1"
                                            value="Y"
                                            dense
                                            @change="changeActiveFlag(item)"
                                        ></v-switch>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-btn small text color="warning" @click="showItem(item)"><v-icon small class="mr-2">mdi-pencil</v-icon> Edit</v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog class="rounded-xl" v-model="dialog" max-width="800px" style="background: #fff;">                
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="p-0" style="padding:0px !important">
                                <v-card class="rounded-xl elevation-12" style="background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;">
                                    <v-card-title style="font-weight:500">
                                        <v-row align="center" justify="center" style="margin-top: -16px;">
                                            <v-col cols="12" xs="12" sm="6" md="4" style="background: #003871;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                                <h4 class="text-white text-center" style="font-weight:500">
                                                   Master Project Baru
                                                </h4>
                                                <h5 class="text-white text-center">Master Partner</h5>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                    <v-container fluid>
                                        <v-row class="mt-lg-5">
                                            <!-- <v-col cols="12" xs="12" sm="6" md="2" class="pt-0 pb-0">
                                                <p class="blue-lcd ma-0 pa-0">Partner ID</p>
                                                <v-text-field
                                                    solo
                                                    label="Partner ID"
                                                    persistent-hint
                                                    required
                                                    v-model="partner_id"
                                                    class="ma-0 pa-0 border-12"
                                                    >
                                                </v-text-field>     
                                            </v-col> -->
                                            <v-col class="col-6" sm="6" md="4">
                                                <p class="blue-lcd ma-0 pa-0">Jenis Partner</p>
                                                <v-text-field
                                                    solo
                                                    label="Jenis Partner"
                                                    persistent-hint
                                                    required
                                                    v-model="jenis_partner"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                <p class="blue-lcd ma-0 pa-0">Partner Name</p>
                                                <v-text-field
                                                    solo
                                                    label="Partner Name"
                                                    persistent-hint
                                                    required
                                                    v-model="partner_desc"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                    >
                                                </v-text-field>
                                            </v-col>  
                                            <v-col class="col-6" sm="6" md="4">
                                              <p class="blue-lcd ma-0 pa-0">Assosiasi</p>
                                              <v-autocomplete 
                                              dense 
                                              solo 
                                              class="ma-0 pa-0 border-12" 
                                              hide-details=true 
                                              v-model="asosisi"
                                              :items="asosi" 
                                              default="" 
                                              item-value="str1" 
                                              item-text="str2" 
                                              label="Assosiasi" 
                                              clearable>
                                              </v-autocomplete>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                <p class="blue-lcd ma-0 pa-0">PIC</p>
                                                <v-text-field
                                                    solo
                                                    label="PIC"
                                                    persistent-hint
                                                    required
                                                    v-model="partner_pic"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                    >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                <p class="blue-lcd ma-0 pa-0">Province</p>
                                              <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="partner_province"
                                                  :items="provinces" default="" item-value="province" item-text="province" label="Province"
                                                  @change="(event) => getCity(event)" clearable>
                                              </v-autocomplete>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                    <p class="blue-lcd ma-0 pa-0">City</p>
                                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="partner_city"
                                                    :items="cities" default="" item-value="city" item-text="city" label="City" clearable>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="8">
                                                <p class="blue-lcd ma-0 pa-0">Address</p>
                                                <v-text-field
                                                    solo
                                                    label="Address"
                                                    persistent-hint
                                                    required
                                                    v-model="partnerr_address"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                <p class="blue-lcd ma-0 pa-0">Phone</p>
                                                <v-text-field
                                                    solo
                                                    label="Phone"
                                                    persistent-hint
                                                    required
                                                    v-model="partner_phone"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                    >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                              <p class="blue-lcd ma-0 pa-0">Sales Man</p>
                                              <v-autocomplete 
                                              dense 
                                              solo 
                                              class="ma-0 pa-0 border-12" 
                                              hide-details=true 
                                              v-model="salesman_id"
                                              :items="sales" 
                                              default="" 
                                              item-value="salesman_id" 
                                              item-text="salesman_name" 
                                              label="Sales" 
                                              clearable>
                                              </v-autocomplete>
                                            </v-col>
                                            <!-- <v-col cols="12" xs="12" sm="6" md="2" class="pt-0 pb-0">
                                                <p class="blue-lcd ma-0 pa-0">Sales Man</p>
                                                <v-text-field
                                                    solo
                                                    label="Sales"
                                                    persistent-hint
                                                    required
                                                    v-model="salesman_id"
                                                    class="ma-0 pa-0 border-12"
                                                    >
                                                </v-text-field>
                                            </v-col>  -->         
                                          <v-col class="col-12" sm="12" md="6">
                                              <p class="blue-lcd ma-0 pa-0">Status</p>
                                              <div class="d-flex mt-0 pt-0">
                                                  <v-checkbox
                                                      v-model="active_flag"
                                                      label="Active"
                                                      value="Y"
                                                      class="mr-8 ma-0"
                                                      hide-details
                                                  ></v-checkbox>
                                                  <v-checkbox
                                                      v-model="active_flag"
                                                      label="Non Active"
                                                      value="N"
                                                      class="mr-8 ma-0"
                                                      hide-details
                                                  ></v-checkbox>
                                              </div>
                                          </v-col>
                                        </v-row>
                                        <v-row justify="center" class="mt-8">
                                            <v-col cols="12" xs="12" sm="6" md="4">
                                                <v-btn block class="rounded-l p-4 text-p" color="#fff" @click="dialog = false">Batal</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4">
                                                <v-btn block class="rounded-l text-white p-4 text-p" color="#003871" type="submit" @click="submit()">Submit</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                  </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog class="rounded-xl" v-model="dialog2" max-width="800px"  transition="dialog-bottom-transition" style="background: #fff;">                
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="p-0" style="padding:0px !important">
                                <v-card class="rounded-xl elevation-12" style="background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;">
                                    <v-card-title style="font-weight:600">
                                        <v-row align="center" justify="center" style="margin-top: -16px;">
                                            <v-col cols="12" xs="12" sm="6" md="4" style="background: #003871;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                                <h4 class="text-white text-center" style="font-weight:700">
                                                  Edit Master Project 
                                                </h4>
                                                <h5 class="text-white text-center">Master Partner</h5>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                    <v-container fluid>
                                        <v-row class="mt-lg-5">
                                            <v-col class="col-6" sm="6" md="3">
                                                <p class="blue-lcd ma-0 pa-0">Partner ID</p>
                                                <v-text-field
                                                    solo
                                                    label="Partner ID"
                                                    persistent-hint
                                                    required
                                                    v-model="partner_id"
                                                    class="ma-0 pa-0 border-12"
                                                    disabled
                                                    hide-details
                                                    >
                                                </v-text-field>     
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="3">
                                                <p class="blue-lcd ma-0 pa-0">Jenis Partner</p>
                                                <v-text-field
                                                    solo
                                                    label="Jenis Partner"
                                                    persistent-hint
                                                    required
                                                    v-model="jenis_partner"
                                                    class="ma-0 pa-0 border-12" 
                                                    hide-details
                                                    >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="3">
                                                <p class="blue-lcd ma-0 pa-0">Partner Name</p>
                                                <v-text-field
                                                    solo
                                                    label="Partner Name"
                                                    persistent-hint
                                                    required
                                                    v-model="partner_desc"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                    >
                                                </v-text-field>
                                            </v-col> 
                                            <v-col class="col-6" sm="6" md="3">
                                                <p class="blue-lcd ma-0 pa-0">Assosiasi</p>
                                                <v-text-field
                                                    solo
                                                    label="Assosiasi"
                                                    persistent-hint
                                                    required
                                                    v-model="asosisi"
                                                    class="ma-0 pa-0 border-12"     
                                                    hide-details                                      
                                                    >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                <p class="blue-lcd ma-0 pa-0">PIC</p>
                                                <v-text-field
                                                    solo
                                                    label="PIC"
                                                    persistent-hint
                                                    required
                                                    v-model="partner_pic"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                    >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                <p class="blue-lcd ma-0 pa-0">Province</p>
                                              <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="partner_province"
                                                  :items="provinces" default="" item-value="province" item-text="province" label="Province"
                                                  @change="(event) => getCity(event)" clearable>
                                              </v-autocomplete>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                    <p class="blue-lcd ma-0 pa-0">City</p>
                                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="partner_city"
                                                    :items="cities" default="" item-value="city" item-text="city" label="City" clearable>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col class="col-12" sm="12" md="8">
                                                <p class="blue-lcd ma-0 pa-0">Address</p>
                                                <v-text-field
                                                    solo
                                                    label="Address"
                                                    persistent-hint
                                                    required
                                                    v-model="partnerr_address"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                    >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                                <p class="blue-lcd ma-0 pa-0">Phone</p>
                                                <v-text-field
                                                    solo
                                                    label="Phone"
                                                    persistent-hint
                                                    required
                                                    v-model="partner_phone"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details
                                                    >
                                                </v-text-field>
                                            </v-col>
                                            <v-col class="col-6" sm="6" md="4">
                                              <p class="blue-lcd ma-0 pa-0">Sales Man</p>
                                              <v-autocomplete 
                                              dense 
                                              solo 
                                              class="ma-0 pa-0 border-12" 
                                              hide-details=true 
                                              v-model="salesman_id"
                                              :items="sales" 
                                              default="" 
                                              item-value="salesman_id" 
                                              item-text="salesman_name" 
                                              label="Sales" 
                                              clearable>
                                              </v-autocomplete>
                                          </v-col>
                                          <v-col class="col-12" sm="12" md="6">
                                              <p class="blue-lcd ma-0 pa-0">Status</p>
                                              <div class="d-flex mt-0 pt-0">
                                                  <v-checkbox
                                                      v-model="active_flag"
                                                      label="Active"
                                                      value="Y"
                                                      class="mr-8 ma-1"
                                                      hide-details
                                                  ></v-checkbox>
                                                  <v-checkbox
                                                      v-model="active_flag"
                                                      label="Non Active"
                                                      value="N"
                                                      class="mr-8 ma-1"
                                                      hide-details
                                                  ></v-checkbox>
                                              </div>
                                          </v-col>
                                        </v-row>
                                        <v-row justify="center" class="mt-8">
                                            <v-col cols="12" xs="12" sm="6" md="4">
                                                <v-btn block class="rounded-l p-4 text-p" color="#fff" @click="dialog2 = false">Batal</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4">
                                                <v-btn block class="rounded-l text-white p-4 text-p" color="#003871" type="submit" @click="update()">Submit</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                  </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
            </v-col>
      </v-row>
  </v-container>
</template>

<script>
import {projectTracking} from "../../../backend-api/sr/project/project_tracking"
export default {
  data(){
    return{
        snackbar: {
            color: null,
            icon: null,
            mode: null,
            position: "top",
            text: null,
            timeout: 7500,
            title: null,
            visible: false
        },
        breadcumbs: [
            {
            text: 'Sunrise Steel',
            disabled: false,
            href: '/admin/sr',
            },
            {
            text: 'Project',
            disabled: true,
            href: 'breadcrumbs_link_2',
            },
            {
            text: 'Master Partner',
            disabled: true,
            href: 'breadcrumbs_link_2',
            },
        ],
        dialog:false,
        dialog2:false,
        datas:[],
        headers: [
            { text: 'ID', value: 'partner_id' },
            { text: 'Jenis Partner', value: 'jenis_partner' },
            { text: 'Create Date', value: 'created_date' },
            { text: 'Alamat', value: 'partnerr_address' },
            { text: 'Provinsi', value: 'partner_province' },
            { text: 'Kota', value: 'partner_city' },
            { text: 'PIC', value: 'partner_pic' },
            { text: 'Partner Name', value: 'partner_desc' },
            { text: 'No HP',  value: 'partner_phone', sortable: false },
            { text: 'Sales',  value: 'salesman_name', sortable: false },
            { text: 'Status',  value: 'active_flag', sortable: false },
            { text: 'Actions',  value: 'actions', sortable: false },
            
        ],
        loading:false,
        partner_id:'',
        partner_desc:'',
        active_flag:'',
        jenis_partner:'',
        partnerr_address:'',
        partner_city:'',
        partner_province:'',
        partner_pic:'',
        partner_phone:'',
        asosisi:'',
        salesman_id:'',
        sales:[],
        saleses:'',
        province:'',
        provinces:[],
        city:'',
        cities:[],
        provinceDialog:'',
        provincesDialog:[],
        asosi:[],
        asos:'',
        search: ''
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getData()
        await this.getProvince()
        await this.getCity()
        await this.updatesales()
        await this.updateaso()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        async getProvince(event){
            const respData = await projectTracking.fetchProvince(``, null, false, false, false)
            if (respData.status === 200) {
                this.provincesDialog = respData.data.data
                this.provinces = respData.data.data
            }
        },
        async getCity(event){
            const respData = await projectTracking.fetchCity(`?province=${event}`, null, false, false, false)
            if (respData.status === 200) {
                this.citiesDialog = respData.data.data
                this.cities = respData.data.data
            }
        },
        updatesales(){
            axios.get(`${process.env.VUE_APP_URL}/v2/sr/partner/salesmin`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.sales = res.data.data
                
            });
        },
        updateaso(){
            axios.get(`${process.env.VUE_APP_URL}/v2/sr/partner/aso`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.asosi = res.data.data
                
            });
        },
        create(){
            this.dialog = true
            this.partner_id = ''
            this.partner_city = ''
            this.partner_desc = ''
            this.jenis_partner = ''
            this.partnerr_address = ''
            this.partner_phone = ''
            this.partner_pic = ''
            this.asosisi = ''
            this.salesman_id = ''
        },
        async getData(){
            this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/v2/sr/partner`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.datas = res.data.data
                
            })
        },
        async submit(){
            this.loading = true
            if (this.jenis_partner === '' || this.jenis_partner === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Partner",
                    visible: true
                    };
                return false;
            }
            if (this.asosisi === '' || this.asosisi === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Assosiasi",
                    visible: true
                    };
                return false;
            }
            if (this.partner_pic === '' || this.partner_pic === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Pic",
                    visible: true
                    };
                return false;
            }
            if (this.partnerr_address === '' || this.partnerr_address === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Address",
                    visible: true
                    };
                return false;
            }
            if (this.partner_province === '' || this.partner_province === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Province",
                    visible: true
                    };
                return false;
            }
            if (this.partner_city === '' || this.partner_city === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert City",
                    visible: true
                    };
                return false;
            }
            if (this.partner_phone === '' || this.partner_phone === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Phone",
                    visible: true
                    };
                return false;
            }
            if (this.salesman_id === '' || this.salesman_id === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert SalesMan",
                    visible: true
                    };
                return false;
            }
            if (this.partner_desc === '' || this.partner_desc === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Description",
                    visible: true
                    };
                return false;
            }
            if (this.active_flag === '' || this.active_flag === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Active",
                    visible: true
                    };
                return false;
            }
            
            await axios.post(`${process.env.VUE_APP_URL}/v2/sr/partner/CreateData`, {
                'partner_desc' :this.partner_desc,
                'active_flag' :this.active_flag,
                'jenis_partner': this.jenis_partner,
                'partnerr_address':this.partnerr_address,
                'partner_city':this.partner_city,
                'partner_province':this.partner_province,
                'partner_pic': this.partner_pic,
                'partner_phone':this.partner_phone,
                'asosisi':this.asosisi,
                'salesman_id':this.salesman_id       
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.dialog = false
                this.loading = false
                this.getData()
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Created Data",
                    visible: true
                };
            }).catch(err => {
                this.dialog = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async changeActiveFlag(item){
                //console.log(item);

            this.loading = true

            if (item.active_flag == null) {
                var status = "N"
            } else{
                var status = "Y"
            } 

            await axios.put(`${process.env.VUE_APP_URL}/v2/sr/partner/UpdateStatus`, {
                'partner_id': item.partner_id,
                'active_flag' : status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading = false

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully update Status",
                    visible: true
                };

                // this.getData()
            }).catch(err => {
                this.loading = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        }, 
        showItem(item){
            //console.log(item)
            this.dialog2 = true
            this.active_flag = item.active_flag
            this.asosisi = item.asosisi
            this.jenis_partner = item.jenis_partner
            this.partner_city = item.partner_city
            this.partner_desc = item.partner_desc
            this.partner_id = item.partner_id
            this.partner_phone = item.partner_phone
            this.partner_pic = item.partner_pic
            this.partner_province = item.partner_province
            this.partnerr_address = item.partnerr_address
            this.salesman_id = item.salesman_id
            this.getCity(this.partner_province)
        },
        async update(){
            this.loading = true
            if (this.jenis_partner === '' || this.jenis_partner === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Partner",
                    visible: true
                    };
                return false;
            }
            if (this.asosisi === '' || this.asosisi === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Assosiasi",
                    visible: true
                    };
                return false;
            }
            if (this.partner_pic === '' || this.partner_pic === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Pic",
                    visible: true
                    };
                return false;
            }
            if (this.partnerr_address === '' || this.partnerr_address === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Address",
                    visible: true
                    };
                return false;
            }
            if (this.partner_province === '' || this.partner_province === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Province",
                    visible: true
                    };
                return false;
            }
            if (this.partner_city === '' || this.partner_city === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert City",
                    visible: true
                    };
                return false;
            }
            if (this.partner_phone === '' || this.partner_phone === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Phone",
                    visible: true
                    };
                return false;
            }
            if (this.salesman_id === '' || this.salesman_id === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert SalesMan",
                    visible: true
                    };
                return false;
            }
            if (this.partner_desc === '' || this.partner_desc === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Description",
                    visible: true
                    };
                return false;
            }
            if (this.active_flag === '' || this.active_flag === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Active",
                    visible: true
                    };
                return false;
            }
            
            await axios.put(`${process.env.VUE_APP_URL}/v2/sr/partner/Edit`, {
                'partner_id': this.partner_id,
                'partner_desc' :this.partner_desc,
                'active_flag' :this.active_flag,
                'jenis_partner': this.jenis_partner,
                'partnerr_address':this.partnerr_address,
                'partner_city':this.partner_city,
                'partner_province':this.partner_province,
                'partner_pic': this.partner_pic,
                'partner_phone':this.partner_phone,
                'asosisi':this.asosisi,
                'salesman_id':this.salesman_id       
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.dialog2 = false
                this.loading = true
                this.getData()
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Edit Data",
                    visible: true
                };
            }).catch(err => {
                this.dialog = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },   
    }

}
</script>
